@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;600;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;500;600;700&family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");
*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

html,
body {
  scroll-behavior: smooth;
  overflow-x: hidden;
  transition: all 0.2s ease-in;
}

body {
  line-height: 1.5;
  font-size: 16px !important;
  -webkit-font-smoothing: antialiased;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
  font-size: 100% !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

ol,
ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button:hover {
  cursor: pointer !important;
}

.back-button:hover {
  cursor: pointer;
}

svg:hover {
  cursor: pointer;
}

.wrapper {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
}

* {
  font-family: "Outfit", sans-serif;
}

header {
  width: 100%;
  max-width: 1800px;
  transition: all 0.45s ease-in;
  position: fixed;
  top: 0;
  background: #fff;
  z-index: 9999;
}
header .top_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.3em 1.3em 1.3em 3em;
  background: #fff;
  width: 100%;
  z-index: 9999;
}
@media (max-width: 400px) {
  header .top_content {
    width: 100%;
    padding: 0.8rem 0.4rem 0.8rem 1.1rem;
  }
}
header .top_content ul .horizontal_line {
  width: 100%;
  height: 1.7px;
  background-color: rgb(164, 164, 164);
}
header .top_content ul .line {
  margin-bottom: 1em;
}
header .top_content ul .l {
  font-size: 1.2rem;
  color: #757575;
  font-weight: 500;
}
header .top_content .img_logo {
  width: 10rem;
}
@media (max-width: 600px) {
  header .top_content .img_logo {
    width: 30%;
  }
}
header .top_content .profile {
  display: flex;
  align-items: center;
  gap: 1em;
  position: relative;
}
@media (max-width: 600px) {
  header .top_content .profile {
    font-size: 0.9rem;
  }
}
@media (max-width: 600px) {
  header .top_content .profile h4 {
    display: none;
  }
}
header .top_content .btn_b {
  margin-right: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
}
@media (max-width: 500px) {
  header .top_content .btn_b {
    margin-right: 0.5em;
  }
}
@media (max-width: 400px) {
  header .top_content .btn_b {
    gap: 0.5px;
  }
}
@media (max-width: 826px) {
  header .top_content .btn_b {
    display: none;
  }
}
header .top_content .btn_b .signin_btn {
  color: #287BDC;
  border: none;
  outline: none;
  border: 1px solid #287BDC;
}
@media (max-width: 500px) {
  header .top_content .btn_b .signin_btn {
    padding: 0.1em !important;
    font-size: 0.7rem !important;
  }
}
header .top_content .btn_b .login_btn {
  background-color: #287BDC;
  color: #fff;
  outline: none;
  border: none;
}
@media (max-width: 500px) {
  header .top_content .btn_b .login_btn {
    font-size: 0.8rem;
  }
}

.d {
  margin: auto;
}
@media (max-width: 750px) {
  .d {
    margin-right: 1em;
  }
}

.explorer_navigation_header_list {
  display: flex;
  transition: all 0.3s ease-in;
  gap: 6.5em;
  align-items: center;
}
@media (max-width: 900px) {
  .explorer_navigation_header_list {
    gap: 3em;
  }
}
@media (max-width: 900px) {
  .explorer_navigation_header_list {
    display: none;
  }
}
.explorer_navigation_header_list .link {
  color: #000;
  padding: 0.3em;
  transition: all 0.35s ease-in;
}
.explorer_navigation_header_list .link:hover {
  border-bottom: 3px solid #287BDC;
  transition: all 0.3s ease-in;
}
.explorer_navigation_header_list .link li {
  font-size: 1.2rem;
  font-weight: 500;
}
@media (max-width: 1166px) {
  .explorer_navigation_header_list .link li {
    font-size: 1rem;
  }
}

.active {
  border-bottom: 3px solid #287BDC;
}

.mobile_main {
  width: 100%;
  height: 100vh;
  position: fixed;
  right: 100%;
  display: block;
  z-index: 98;
  transition: 0.4s;
  background-color: #fff;
  opacity: 0.9;
  z-index: 2;
}
@media (min-width: 900px) {
  .mobile_main {
    display: none;
  }
}

.mobile_main_click {
  width: 100%;
  min-height: 100vh;
  position: fixed;
  right: 0;
  display: block;
  z-index: 98;
  transition: 0.4s;
  background-color: #fff;
  opacity: 0.9;
  z-index: 2;
}
@media (min-width: 900px) {
  .mobile_main_click {
    display: none;
  }
}

.mobile .lin {
  transition: all 0.5s ease;
}
.mobile .lin .mobile_nav_line {
  border: 1px solid rgb(201, 199, 199);
}
.mobile .list {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: -moz-fit-content;
  width: fit-content;
  margin: 2em auto auto;
}
.mobile .list .link {
  color: #000;
  transition: all 0.35s ease-in;
  margin-bottom: 2em;
}
.mobile .list .link:hover {
  border-bottom: 3px solid #287BDC;
  transition: all 0.3s ease-in;
}
.mobile .list .link li {
  font-size: 1.5rem;
  font-weight: 500;
}
@media (max-width: 420px) {
  .mobile .list .link li {
    font-size: 1rem;
  }
}
.mobile .list .active {
  border-bottom: 3px solid #287BDC;
  width: -moz-fit-content;
  width: fit-content;
}

.bars {
  display: none;
}
@media (max-width: 900px) {
  .bars {
    display: block;
  }
}
@media (max-width: 400px) {
  .bars {
    width: 20px !important;
    height: 20px !important;
    margin-right: 1em;
  }
}

.popup_mobile {
  margin: auto;
  display: flex;
  justify-content: center;
}

.btn_b_mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5em;
}
.btn_b_mobile .signin_btn {
  border: 1.5px solid #287BDC;
  color: #287BDC;
  outline: none;
}
@media (min-width: 400px) {
  .btn_b_mobile .signin_btn {
    font-size: 0.8rem;
  }
}
.btn_b_mobile .login_btn {
  width: 9rem;
  background-color: #287BDC;
  color: #fff;
  outline: none;
  border: none;
}
@media (min-width: 400px) {
  .btn_b_mobile .login_btn {
    font-size: 0.8rem;
  }
}

@media (max-width: 900px) {
  .avat {
    display: none;
  }
}

.slideMain {
  position: relative;
  height: 100%;
}
.slideMain .heart {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  padding: 0.1em;
  background: #287BDC;
  position: absolute;
  top: 15%;
  transform: translate(0, -50%);
  right: 24px;
  font-size: 45px;
  color: #fff;
  z-index: 1;
  cursor: pointer;
}
.slideMain .arrow_container .leftArrowStyles {
  width: 38px;
  height: 38px;
  background: rgb(40, 123, 220);
  border-radius: 50%;
  opacity: 0.5;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 32px;
  font-size: 45px;
  color: #fff;
  z-index: 1;
  cursor: pointer;
  padding: 0.2em;
}
.slideMain .arrow_container .rightArrowStyles {
  width: 38px;
  height: 38px;
  background: rgb(40, 123, 220);
  border-radius: 50%;
  opacity: 0.5;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 32px;
  font-size: 45px;
  color: #fff;
  z-index: 1;
  cursor: pointer;
  padding: 0.2em;
}
.slideMain .dotsContainerStyles {
  display: flex;
  gap: 0.3rem;
  justify-content: center;
  margin-top: 0.5rem;
}
.slideMain .dotsContainerStyles .dotStyle {
  cursor: pointer;
  font-size: 20px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #c2c1c1;
  transition: all 0.25s ease-in-out;
}
.slideMain .dotsContainerStyles .active_class {
  cursor: pointer;
  font-size: 20px;
  width: 20px;
  height: 10px;
  border-radius: 12px;
  background-color: #287BDC;
  transition: all 0.25s ease-in-out;
}

main {
  margin-top: 1.5em;
}
main .content {
  margin: auto;
}
main .content .slider_div {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* 3 columns with equal width */
  gap: 30px;
  width: 95%;
  margin: auto;
  /* Media query for screens smaller than 768px */
}
@media (max-width: 900px) {
  main .content .slider_div {
    grid-template-columns: repeat(3, 1fr);
    width: 90%;
    /* 2 columns per row */
  }
}
@media (max-width: 700px) {
  main .content .slider_div {
    grid-template-columns: repeat(2, 1fr);
    /* 2 columns per row */
  }
}
@media (max-width: 600px) {
  main .content .slider_div {
    grid-template-columns: repeat(1, 1fr);
    gap: 4rem;
  }
}
main .content .slider_div section {
  width: 40%;
  padding: 1rem;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
main .content .slider_div section .slider {
  height: 100%;
  margin: auto;
}
main .content .slider_div section .slider .img {
  height: 320px;
  width: 100%;
  border-radius: 15px;
}
@media (max-width: 900px) {
  main .content .slider_div section .slider .img {
    max-height: 480px;
  }
}
main .content .slider_div section .slider .info {
  margin-top: 1.5em;
}
main .content .slider_div section .slider .info h2 {
  color: #000;
  font-size: 24px;
  font-weight: 600;
  text-align: left;
}
main .content .slider_div section .slider .info p {
  color: #757575;
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  width: 100%;
}
main .content .slider_div section .slider .info .price {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 0.8em;
  margin-top: 0.5em;
}
main .content .slider_div section .slider .info .price span {
  color: rgb(0, 201, 116);
  font-size: 16px;
  font-weight: 600;
  text-align: left;
}
main .content .slider_div section .slider .info .detailss {
  display: flex;
  align-items: center;
  gap: 0.8em;
  margin-top: 0.5em;
  margin-bottom: 1rem;
}
@media (max-width: 900px) {
  main .content .slider_div section .slider .info .detailss {
    margin-bottom: 0;
  }
}
main .content .slider_div section .slider .info .detailss ul {
  display: flex;
  align-items: center;
  gap: 0.6em;
  margin: auto;
}
main .content .slider_div section .slider .info .detailss ul img {
  width: 18px !important;
}
@media (max-width: 900px) {
  main .content .slider_div section .slider .info .detailss ul img {
    width: 20px !important;
  }
}
@media (max-width: 900px) {
  main .content .slider_div section .slider .info .detailss ul {
    justify-content: space-between;
  }
}
main .content .slider_div section .slider .info .detailss ul li {
  color: #757575;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}
@media (max-width: 700px) {
  main .content .slider_div section .slider .info .detailss ul li {
    font-size: 14px;
  }
}

.no_property {
  display: flex;
  justify-content: center;
  margin: auto;
  margin-inline: auto;
  position: relative;
  width: 100%;
}
.no_property .gif {
  max-width: 400px;
}
@media (max-width: 700px) {
  .no_property .gif {
    max-width: 300px;
    width: auto;
    height: 400px;
  }
}
.no_property .gif img {
  cursor: pointer;
}
@media (max-width: 700px) {
  .no_property .gif img {
    width: 100%;
  }
}
.no_property .gif .btn {
  width: -moz-fit-content;
  width: fit-content;
  margin-inline: auto;
  margin: 1.2rem auto 5rem;
  display: flex;
  justify-content: center;
}
.no_property .gif .btn button {
  background-color: #287BDC !important;
  color: #fff;
  border: none;
  outline: none;
}

footer {
  background: #000;
  margin-top: 1rem;
  font-family: "Poppins", sans-serif;
}
footer .subscribe_letter {
  background: #287BDC;
  border-radius: 12px;
  padding: 1.8em;
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: auto;
}
footer .subscribe_letter h6 {
  color: #fff;
  font-size: 23px;
  font-weight: 500;
  text-align: center;
  font-family: "Poppins" !important;
}
footer .subscribe_letter .email {
  display: flex;
  gap: 1.2em;
  width: 50%;
}
footer .subscribe_letter .email .input {
  border: 1px solid #fff;
  display: flex;
  gap: 1em;
  align-items: center;
  padding: 0.8em;
  border-radius: 7px;
  width: 100%;
}
footer .subscribe_letter .email .input input {
  flex: 1;
  border: none;
  background: #287BDC;
  outline: none;
  color: #fff;
}
footer .subscribe_letter .email .input input::-moz-placeholder {
  color: #fff;
  font-weight: 400;
  opacity: 0.6;
  font-family: "poppins" !important;
  font-size: 16px;
}
footer .subscribe_letter .email .input input::placeholder {
  color: #fff;
  font-weight: 400;
  opacity: 0.6;
  font-family: "poppins" !important;
  font-size: 16px;
}
@media (max-width: 600px) {
  footer .subscribe_letter .email .input input::-moz-placeholder {
    font-size: 0.9rem;
  }
  footer .subscribe_letter .email .input input::placeholder {
    font-size: 0.9rem;
  }
}
@media (max-width: 490px) {
  footer .subscribe_letter .email .input input::-moz-placeholder {
    font-size: 0.8rem;
  }
  footer .subscribe_letter .email .input input::placeholder {
    font-size: 0.8rem;
  }
}
footer .subscribe_letter .email button {
  outline: none;
  border: none;
  color: #287BDC;
  font-size: 1em;
  padding: 0.8em 1em 0.8em;
  border-radius: 10px;
  cursor: pointer;
  font-family: "poppins" !important;
}
@media (max-width: 600px) {
  footer .subscribe_letter .email button {
    padding: 0.3em;
    font-size: 0.8rem;
  }
}
@media (max-width: 400px) {
  footer .subscribe_letter .email button {
    font-size: 0.7rem !important;
    padding: 0 0.8em 0;
  }
}
footer section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 4rem 4rem 4rem;
  margin: auto;
}
@media (max-width: 900px) {
  footer section {
    width: 100%;
    flex-wrap: wrap;
    padding: 3rem;
  }
}
footer section .rentease .rent {
  margin-bottom: 1rem;
}
@media (max-width: 600px) {
  footer section .rentease .rent {
    top: 0.1em;
    margin-bottom: 0.5em;
  }
}
footer section .rentease p {
  color: #737373;
  font-size: 0.98em;
  font-weight: 500;
  text-align: left;
  width: 70%;
  line-height: 21px;
}
@media (max-width: 600px) {
  footer section .rentease p {
    width: 90%;
    margin: 0;
  }
}
footer section .comp {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-right: 3rem;
}
@media (max-width: 600px) {
  footer section .comp {
    margin-right: 0;
    margin-top: 4em;
    width: 100%;
    flex-wrap: wrap;
  }
}
footer section .comp .company {
  font-family: "poppins" !important;
}
@media (max-width: 900px) {
  footer section .comp .company {
    margin-bottom: 1.5rem;
  }
}
footer section .comp .company .first {
  font-weight: 800 !important;
  font-family: "poppins" !important;
  color: #fff;
  text-align: left;
  margin-bottom: 1em;
  font-size: 1rem;
}
@media (max-width: 900px) {
  footer section .comp .company .first {
    margin-bottom: 0;
  }
}
footer section .comp .company li {
  cursor: pointer;
  font-size: 0.9rem;
  font-family: "poppins" !important;
  font-weight: 500;
  color: #fff;
  text-align: left;
  padding: 0.2rem;
}
@media (max-width: 900px) {
  footer section .comp .company li {
    padding: 0.2rem;
  }
}
footer section .app {
  margin-left: 3.5em;
}
@media (max-width: 900px) {
  footer section .app {
    margin: 0;
    width: 100%;
  }
}
@media (max-width: 600px) {
  footer section .app .social_img {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
  }
}
footer section .app .social_img .gg {
  margin-bottom: 1.1em;
}
footer section .app h4 {
  color: #fff;
  font-size: 0.98em;
  font-weight: 500;
  text-align: left;
  width: 30%;
  line-height: 21px;
  margin-bottom: 1.5em;
}
@media (max-width: 900px) {
  footer section .app h4 {
    width: 100%;
    margin-bottom: 1em;
  }
}
footer section .app .social_icons {
  display: flex;
  gap: 0.8em;
  width: 100%;
}
footer section .app .social_icons .icons {
  width: 31px;
  height: 31px;
  border-radius: 50%;
  padding: 0.55em;
  background: rgb(70, 70, 70);
}
footer section .app .social_icons .inst, footer section .app .social_icons .fb {
  padding: 0.5em;
}
@media (max-width: 900px) {
  footer section .app .social_icons .tw, footer section .app .social_icons .yt {
    padding: 0.5em;
  }
}
footer .line {
  border: 1px solid #fff;
}
footer .rights {
  font-size: 20px;
  text-align: center;
  padding: 4rem;
  color: #fff;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 700px) {
  footer .rights {
    font-size: 1.125rem;
  }
}

.pagination-container .pagination {
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
  align-items: center;
  gap: 2rem;
}
.pagination-container .pagination .active {
  color: #fff;
  background: #00a8ff;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}
.pagination-container .pagination .page_main {
  color: #00a8ff;
  background: #f5f0f0;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}
.pagination-container .pagination .page-number {
  cursor: pointer;
}

.property_container {
  transition: all 0.35s ease-in;
  margin-top: 8em;
  width: 100%;
}
@media (max-width: 480px) {
  .property_container {
    margin-top: 6rem;
  }
}
.property_container .property_details_content {
  margin: auto;
  width: 70%;
}
@media (max-width: 1333px) {
  .property_container .property_details_content {
    width: 75%;
  }
}
@media (max-width: 1210px) {
  .property_container .property_details_content {
    width: 80%;
  }
}
@media (max-width: 1040px) {
  .property_container .property_details_content {
    width: 90%;
  }
}
.property_container .property_details_content .image_div {
  display: flex;
  align-items: center;
  gap: 2em;
  width: 90%;
}
.property_container .property_details_content .image_div .img {
  width: 60%;
  border-radius: 5px;
}
.property_container .property_details_content .image_div .img img {
  width: 95%;
}
.property_container .property_details_content .image_div .aside_image {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.property_container .property_details_content .image_div .aside_image img {
  width: 78%;
  border-radius: 5px;
}
.property_container .property_details_content .info_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
@media (max-width: 700px) {
  .property_container .property_details_content .info_container {
    flex-wrap: wrap;
  }
}
@media (max-width: 900px) {
  .property_container .property_details_content .info_container .info {
    width: 100%;
    padding: 0 !important;
  }
}
.property_container .property_details_content .info_container .info .text {
  width: 530px;
}
@media (max-width: 900px) {
  .property_container .property_details_content .info_container .info .text {
    width: 82%;
  }
}
@media (max-width: 400px) {
  .property_container .property_details_content .info_container .info .text {
    width: 95%;
  }
}
.property_container .property_details_content .info_container .info .text .name_top_t {
  display: flex;
  gap: 2em;
  justify-content: space-between;
}
@media (max-width: 472px) {
  .property_container .property_details_content .info_container .info .text .name_top_t {
    width: 100%;
    align-items: center;
    gap: 2em;
  }
}
@media (max-width: 400px) {
  .property_container .property_details_content .info_container .info .text .name_top_t h2 {
    width: 100%;
    font-size: 0.97rem;
  }
}
.property_container .property_details_content .info_container .info .text p {
  width: 90%;
  line-height: 2rem;
  margin-bottom: 3em;
}
@media (max-width: 900px) {
  .property_container .property_details_content .info_container .info .text p {
    text-align: left;
    width: 100%;
  }
}
@media (max-width: 400px) {
  .property_container .property_details_content .info_container .info .text p {
    font-size: 0.89rem;
  }
}
.property_container .property_details_content .info_container .info .horizontal_line {
  border: 1px solid rgb(218, 208, 208);
  width: 70%;
  margin-bottom: 0.7em;
  margin-top: 1em;
  display: block;
}
@media (max-width: 502px) {
  .property_container .property_details_content .info_container .info .horizontal_line {
    width: 100%;
  }
}
.property_container .property_details_content .info_container .info .details {
  display: flex;
  align-items: center;
  gap: 1.2em;
  position: relative;
  margin-bottom: 2em;
}
@media (max-width: 400px) {
  .property_container .property_details_content .info_container .info .details {
    margin-top: 2.5em;
  }
}
.property_container .property_details_content .info_container .info .details .items {
  display: flex;
  align-items: center;
  gap: 0.8em;
}
.property_container .property_details_content .info_container .info .details .items .toilet {
  width: 1rem;
}
@media (max-width: 400px) {
  .property_container .property_details_content .info_container .info .details .items {
    font-size: 0.89rem;
    color: rgb(139, 135, 135);
  }
}
.property_container .property_details_content .info_container .info .details .items img {
  width: 13%;
}
.property_container .property_details_content .info_container .info .house_details {
  display: flex;
  width: 70%;
}
.property_container .property_details_content .info_container .info .house_details .details2 .item {
  display: flex;
  align-items: center;
  margin: 1em;
  gap: 1em;
}
.property_container .property_details_content .info_container .info .house_details .details2 img {
  width: 13%;
}
.property_container .property_details_content .info_container .info .house_details .details3 .item {
  display: flex;
  align-items: center;
  margin: 1em;
  gap: 1em;
}
.property_container .property_details_content .info_container .info .house_details .details3 img {
  width: 13%;
}
.property_container .property_details_content .info_container .rent_option {
  padding: 0.8em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
  border-radius: 5px;
  position: relative;
  width: 35%;
  height: 60%;
  margin-top: 2em;
  position: relative;
}
@media (max-width: 750px) {
  .property_container .property_details_content .info_container .rent_option {
    width: 95%;
  }
}
.property_container .property_details_content .info_container .rent_option .pay {
  border: 2px solid rgb(223, 221, 221);
  margin: 0.8em;
  width: 30%;
  padding: 0.15em;
  border-radius: 12px;
  color: rgb(117, 117, 117);
  font-weight: 400;
  font-size: 0.8rem;
}
.property_container .property_details_content .info_container .rent_option span {
  color: #04c17e;
  font-weight: 600;
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0.8em;
}
.property_container .property_details_content .info_container .rent_option p {
  color: rgb(117, 117, 117);
  font-weight: 300;
  font-size: 0.7rem;
  margin: 0.8em;
}
.property_container .property_details_content .info_container .rent_option button {
  width: 90%;
  border: none;
  outline: none;
  color: #fff;
  background-color: #287BDC;
  margin: 0.8em;
}
.property_container .property_details_content .info_container .rent_option .login_create {
  margin: auto;
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.property_container .property_details_content .info_container .rent_option .login_create .lgn_btn {
  border: none;
  outline: none;
  color: #fff;
  background-color: #287BDC;
  width: 45%;
}
.property_container .property_details_content .info_container .rent_option .login_create .crt_btn {
  outline: none;
  border: 1px solid #287BDC;
  color: #287BDC;
  background-color: #fff;
  width: 45%;
  text-align: center;
}

.saved-carousel-image {
  width: 300px;
  height: 360px;
  border-radius: 10px;
  background-position: center;
}
@media (max-width: 902px) {
  .saved-carousel-image {
    width: 250px;
    height: 250px;
  }
}
@media (max-width: 700px) {
  .saved-carousel-image {
    width: 180px;
    height: 200px;
  }
}
@media (max-width: 696px) {
  .saved-carousel-image {
    width: 290px;
    height: 340px;
  }
}
@media (max-width: 638px) {
  .saved-carousel-image {
    width: 250px;
  }
}
@media (max-width: 600px) {
  .saved-carousel-image {
    width: 420px;
    height: 420px;
  }
}
@media (max-width: 480px) {
  .saved-carousel-image {
    width: 380px;
    height: 380px;
  }
}
@media (max-width: 400px) {
  .saved-carousel-image {
    width: 320px;
  }
}

.carousel-container {
  width: 100%;
}
@media (max-width: 700px) {
  .carousel-container {
    width: 90%;
  }
}
@media (max-width: 696px) {
  .carousel-container {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .carousel-container {
    width: 92%;
  }
}
@media (max-width: 440px) {
  .carousel-container {
    width: 100%;
    margin: auto;
  }
}
@media (max-width: 378px) {
  .carousel-container {
    width: 100%;
  }
}

.caa {
  max-width: 320px;
  min-width: 320px;
}
@media (max-width: 900px) {
  .caa {
    max-width: 275px;
    min-width: 275px;
  }
}
@media (max-width: 700px) {
  .caa {
    max-width: 190px;
    min-width: 190px;
  }
}
@media (max-width: 696px) {
  .caa {
    max-width: 320px;
    min-width: 320px;
  }
}
@media (max-width: 600px) {
  .caa {
    max-width: 450px;
    min-width: 450px;
    margin: auto;
  }
}
@media (max-width: 440px) {
  .caa {
    max-width: 420px;
    min-width: 420px;
  }
}
@media (max-width: 400px) {
  .caa {
    max-width: 350px;
    min-width: 350px;
  }
}
@media (max-width: 375px) {
  .caa {
    max-width: 100%;
    min-width: 1px;
  }
}

@media (max-width: 750px) {
  .detail_icon {
    height: 40px;
    width: 36px;
  }
}
@media (max-width: 500px) {
  .detail_icon {
    display: none;
  }
}

.header_section .btn_div {
  width: 15%;
  margin: auto auto 2em;
  padding-top: 1.5em;
}
@media (max-width: 400px) {
  .header_section .btn_div {
    padding-top: 1.8em;
    width: 27%;
  }
}
.header_section .btn_div button {
  background-color: #287BDC;
  outline: none;
  border: none;
  color: #fff;
  width: 100%;
  cursor: pointer;
}
.header_section .search_parameters {
  padding-top: 10em;
}
@media (max-width: 440px) {
  .header_section .search_parameters {
    padding-top: 4.8em;
  }
}
.header_section .search_parameters .content {
  padding: 0.8em;
}
.header_section .search_parameters .content .input_type {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5em;
}
@media (max-width: 400px) {
  .header_section .search_parameters .content .input_type {
    gap: 0.6em;
  }
}
.header_section .search_parameters .content .input_type input {
  padding: 0.8em;
  border: none;
  outline: none;
  width: 35.5%;
  border-radius: 5px;
}
@media (max-width: 400px) {
  .header_section .search_parameters .content .input_type input::-moz-placeholder {
    font-size: 0.8rem;
  }
  .header_section .search_parameters .content .input_type input::placeholder {
    font-size: 0.8rem;
  }
}
@media (max-width: 600px) {
  .header_section .search_parameters .content .input_type input {
    width: 42%;
  }
}
@media (max-width: 400px) {
  .header_section .search_parameters .content .input_type input {
    width: 60%;
  }
}
.header_section .search_parameters .content .input_type .select {
  width: 17%;
  background-color: #fff;
  height: 3em;
  border-radius: 5px;
  padding-left: 0.8em;
  outline: none;
  border: none;
}
@media (max-width: 600px) {
  .header_section .search_parameters .content .input_type .select {
    width: 25%;
  }
}
@media (max-width: 400px) {
  .header_section .search_parameters .content .input_type .select {
    width: 35%;
  }
}
.header_section .search_parameters .content .details_filter {
  margin: 2em auto auto;
  gap: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 400px) {
  .header_section .search_parameters .content .details_filter {
    gap: 0.6em;
    width: 100%;
  }
}
.header_section .search_parameters .content .details_filter .select {
  background-color: #fff;
  width: 17%;
  background-color: #fff;
  height: 3em;
  border-radius: 5px;
  padding-left: 0.8em;
  outline: none;
  border: none;
}
@media (max-width: 600px) {
  .header_section .search_parameters .content .details_filter .select {
    width: 25%;
  }
}
@media (max-width: 400px) {
  .header_section .search_parameters .content .details_filter .select {
    width: 38%;
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  select {
    -webkit-appearance: none;
  }
}

.location_search_div {
  position: relative;
  width: 60%;
}
@media (min-width: 900px) {
  .location_search_div {
    width: 35%;
  }
}
.location_search_div .location {
  width: 100% !important;
}
.location_search_div .search_values {
  background: #fff;
  width: 100%;
  padding: 0.8em;
  border-radius: 3px;
  margin-top: 0em;
  z-index: 1;
  overflow: hidden;
  overflow-y: auto;
  min-height: -moz-fit-content;
  min-height: fit-content;
  max-height: 300px;
  opacity: 0.95;
}
.location_search_div .search_values p {
  margin: 0.5em auto 0.5em;
  cursor: pointer;
  padding: 0.5em;
}
.location_search_div .search_values p:hover {
  background: rgb(163, 162, 162);
}
.location_search_div .spinner {
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.zoom {
  background: rgb(92, 92, 92);
  opacity: 0.8;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
  height: 1600px;
  bottom: 0;
  transform: 0.2s linear;
}
.zoom .x_mark {
  margin-top: 1rem;
  margin-left: 1rem;
}
@media (max-width: 900px) {
  .zoom {
    height: 2400px;
  }
}

.zoom_content_img {
  border-radius: 8px;
  border: 1px solid rgb(225, 225, 225);
  z-index: 1000000000000000000;
  background: #fff !important;
  position: absolute;
  top: 38%; /* Move the top edge to the vertical center */
  left: 50%; /* Move the left edge to the horizontal center */
  transform: translate(-50%, -50%);
}
@media (max-width: 900px) {
  .zoom_content_img {
    width: 90%;
    top: 35%;
  }
}
@media (max-width: 480px) {
  .zoom_content_img {
    top: 16%;
  }
}
.zoom_content_img .img_main_zoom {
  border-radius: 8px;
  padding: 4rem;
  background: #fff !important;
}
@media (max-width: 900px) {
  .zoom_content_img .img_main_zoom {
    padding: 2rem;
  }
}
.zoom_content_img .img_main_zoom .zoom_ .zoom_image {
  margin: auto;
  max-width: 800px;
}
.zoom_content_img .img_main_zoom .zoom_ .zoom_image img {
  width: 100%;
  border-radius: 10px;
}
.zoom_content_img .img_main_zoom .zoom_ .rent_option {
  padding: 0.8em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
  border-radius: 5px;
  position: relative;
  width: 100%;
  height: 60%;
  margin: 2rem auto auto;
  position: relative;
}
@media (max-width: 750px) {
  .zoom_content_img .img_main_zoom .zoom_ .rent_option {
    width: 95%;
  }
}
@media (max-width: 480px) {
  .zoom_content_img .img_main_zoom .zoom_ .rent_option {
    width: 100%;
  }
}
.zoom_content_img .img_main_zoom .zoom_ .rent_option .pay {
  border: 2px solid rgb(223, 221, 221);
  margin: 0.8em;
  width: 30%;
  padding: 0.15em;
  border-radius: 12px;
  color: rgb(117, 117, 117);
  font-weight: 400;
  font-size: 0.8rem;
}
.zoom_content_img .img_main_zoom .zoom_ .rent_option span {
  color: #04c17e;
  font-weight: 600;
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0.8em;
}
.zoom_content_img .img_main_zoom .zoom_ .rent_option p {
  color: rgb(117, 117, 117);
  font-weight: 300;
  font-size: 0.7rem;
  margin: 0.8em;
}
.zoom_content_img .img_main_zoom .zoom_ .rent_option button {
  width: 90%;
  border: none;
  outline: none;
  color: #fff;
  background-color: #287BDC;
  margin: 0.8em;
}
.zoom_content_img .img_main_zoom .zoom_ .rent_option .login_create {
  margin: auto;
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.zoom_content_img .img_main_zoom .zoom_ .rent_option .login_create .lgn_btn {
  border: none;
  outline: none;
  color: #fff;
  background-color: #287BDC;
  width: 45%;
}
.zoom_content_img .img_main_zoom .zoom_ .rent_option .login_create .crt_btn {
  outline: none;
  border: 1px solid #287BDC;
  color: #287BDC;
  background-color: #fff;
  width: 45%;
  text-align: center;
}/*# sourceMappingURL=main.css.map */