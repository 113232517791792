$color_primary-100: #287BDC;
$color_primary-200: #B8B8B8;
$color_primary-300: #000;
$color_primary-400: #fff;


$color_tertiary-100: #212429;
$color_tertiary-200: #737373;
$color_tertiary-300: #5340FF;
$color_tertiary-400: #D9D9D9;
$color_tertiary-500: rgba(117, 117, 117, 1);
$color_tertiary-600: rgba(0, 201, 116, 1);
$sidebar-border-color: #BDBDBD;
$input-text: #98989E;
$border: #757575;

$error-color: #FF7171;


// fonts size
$md-font-size : 20px;
$sm-font-size : 14px; // .89rem
$ssm-font-size: 16px;
$mds-font-size: 24px;
$xm-font-size: 32px;
$xsm-font-size : 12px;


// font-family
$font-primary: "Outfit", sans-serif;
$poppins : "Poppins", sans-serif;

$border-radius-1: 10px;

$image: "/images/background.png"