footer{
    background: $color_primary-300;
    margin-top: 1rem;
    font-family: 'Poppins', sans-serif;
    .subscribe_letter{
        background: $color_primary-100;
        border-radius: 12px;
        padding: 1.8em;
        width: 75%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin: auto;
        // gap: 3em;
        h6{
            @include styletext(23px, $color_primary-400, 500, center);
            font-family: "Poppins" !important;
           
        }

        .email{
            display: flex;
            // align-items: center;
            gap: 1.2em;
            width: 50%;
           
            .input{
                border: 1px solid #fff;
                display: flex;
                gap: 1em;
                align-items: center;
                padding: .8em;
                border-radius: 7px;
                width: 100%;
                input{
                    // opacity: 0;
                    flex: 1;
                    border: none;
                    background: $color_primary-100;
                    outline: none;
                    color: #fff;
                    &::placeholder{
                        color: #fff;
                        font-weight: 400;
                        opacity: .6;
                        font-family: "poppins" !important;
                        font-size: 16px;
                        @media (max-width: 600px) {
                            font-size: .9rem;
                        }
                        @media (max-width: 490px) {
                            font-size: .8rem;
                        }
                    }
                }
            }
            button{
                outline: none;
                border: none;
                color: $color_primary-100;
                font-size: 1em;
                padding: .8em 1em .8em;
                border-radius: $border-radius-1;
                cursor: pointer;
                font-family: "poppins" !important;
                @media (max-width: 600px) {
                    padding: .3em;
                    font-size: .8rem;
                }
                @media (max-width: 400px) {
                    font-size: .7rem !important;
                    padding: 0 .8em 0;
                }
            }
        }
    }
    section{
        // @include layout;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 4rem 4rem 4rem;
        margin: auto;
        @media (max-width: 900px) {
            width: 100%;
            flex-wrap: wrap;
            padding: 3rem;
        }
        .rentease{
            // margin: 0 auto;
            .rent{
                // position: relative;
                margin-bottom: 1rem;
                @media (max-width: 600px) {
                    top: .1em;
                    margin-bottom: .5em;
                }
            }
            p{
                @include styletext(.98em, $color_tertiary-200, 500, left);
                width: 70%;
                line-height: 21px;
                @media (max-width: 600px) {
                    width: 90%;
                    // line-height: 30px;
                    margin: 0;
                }
              
            }
        }
        .comp{
            display: flex;
            // gap: 2em;
            justify-content: space-between;
            width: 100%;
            margin-right: 3rem;
            @media (max-width: 600px) {
                margin-right: 0;
                margin-top: 4em;
                width: 100%;
                flex-wrap: wrap;
            }
            
            .company{
                font-family: "poppins" !important;
                @media (max-width: 900px) {
                    margin-bottom: 1.5rem;
                }
                .first{
                    font-weight: 800 !important;
                    font-family: "poppins" !important;
                    color: #fff;
                    text-align: left;
                    margin-bottom: 1em;
                    font-size: 1rem;
                    @media (max-width: 900px) {
                        margin-bottom: 0;
                    }
                }
                li{
                    cursor: pointer;
                    font-size: .9rem;
                    font-family: "poppins" !important;
                    font-weight: 500;
                    color: #fff;
                    text-align: left;
                    padding: .2rem;
                    @media (max-width: 900px) {
                        padding: .2rem;
                    }
                }
            }
        }
        .app{
            // padding: 1.2em;
            // margin-top: 5.6em;
            margin-left: 3.5em;
            // border: 2px solid red;
            @media (max-width: 900px) {
                margin: 0;
                width: 100%;
            }
            .social_img{
                @media (max-width: 600px) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 1em;
                }
                .gg{
                margin-bottom: 1.1em;
            }
            }
            h4{
                @include styletext(.98em, $color_primary-400, 500, left);
                width: 30%;
                line-height: 21px;
                margin-bottom: 1.5em;
                @media (max-width: 900px) {
                    width: 100%;
                    // text-align: center;
                    margin-bottom: 1em;
                }
            }
            .social_icons{
                display: flex;
                gap: .8em;
                width: 100%;
                .icons{
                    width: 31px;
                    height: 31px;
                    border-radius: 50%;
                    padding: .55em;
                    background: rgba(70, 70, 70, 1);
                }
                .inst, .fb{
                    padding: .5em;
                }
                .tw, .yt{
                    @media (max-width: 900px) {
                        padding: .5em;
                    }
                }
            }
        }
    }
    .line{
        border: 1px solid $color_primary-400;
        // width: 100%;
    }
    .rights{
        font-size: 20px;
        text-align: center;
        padding: 4rem;
        color: #fff;
        font-family: 'Poppins', sans-serif;
        @media (max-width: 700px) {
            font-size: 1.125rem;
        }
    }
}