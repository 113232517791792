.property_container {
  // padding-left: 15em;
  transition: all 0.35s ease-in;
  margin-top: 8em;
  width: 100%;
  @media (max-width: 480px) {
    margin-top: 6rem;
  }
  .property_details_content {
    margin: auto;
    width: 70%;
    @media (max-width: 1333px) {
      width: 75%;
    }
    @media (max-width: 1210px) {
      width: 80%;
    }
    @media (max-width: 1040px) {
      width: 90%;
    }

    .image_div {
      display: flex;
      align-items: center;
      gap: 2em;
      width: 90%;

      .img {
        width: 60%;
        // height: 70%;
        border-radius: 5px;

        img {
          width: 95%;
        }
      }
      .aside_image {
        display: flex;
        flex-direction: column;
        gap: 1em;
        img {
          width: 78%;
          border-radius: 5px;
        }
      }
    }
    .info_container {
      display: flex;
      justify-content: space-between;
      // align-items: center;
      // padding: 1em;
      width: 100%;
      // margin-bottom: 2em;
      @media (max-width: 700px) {
        flex-wrap: wrap;
      }
      .info {
        @media (max-width: 900px) {
          width: 100%;
          padding: 0 !important;
        }
        .text {
          // margin-right: 4em;
          // width: 806px;
          // padding-right: 1.5em;
          width: 530px;
          @media (max-width: 900px) {
            width: 82%;
          }

          @media (max-width: 400px) {
            width: 95%;
            // margin-left: -1.8em;
          }

          .name_top_t {
            display: flex;
            // align-items: center;
            gap: 2em;
            justify-content: space-between;
            // margin-bottom: 2em;
            @media (max-width: 472px) {
              width: 100%;
              align-items: center;
              gap: 2em;
            }
            h2 {
              // width: 55%;
              @media (max-width: 400px) {
                width: 100%;
                font-size: 0.97rem;
              }
            }
          }
          p {
            width: 90%;
            line-height: 2rem;
            margin-bottom: 3em;
            @media (max-width: 900px) {
              text-align: left;
              width: 100%;
            }
            @media (max-width: 400px) {
              font-size: 0.89rem;
            }
          }
        }
        .horizontal_line {
          border: 1px solid rgb(218, 208, 208);
          width: 70%;
          margin-bottom: 0.7em;
          margin-top: 1em;
          display: block;
          @media (max-width: 502px) {
            width: 100%;
          }
        }
        .details {
          display: flex;
          align-items: center;
          gap: 1.2em;

          position: relative;
          // margin-left: 13.5em;
          // margin-top: 2em;
          margin-bottom: 2em;
          @media (max-width: 400px) {
            // justify-content: center;
            margin-top: 2.5em;
          }
          .items {
            display: flex;
            align-items: center;
            gap: 0.8em;
            .toilet {
              width: 1rem;
            }
            @media (max-width: 400px) {
              font-size: 0.89rem;
              color: rgb(139, 135, 135);
            }
            img {
              width: 13%;
            }
          }
        }
        .house_details {
          display: flex;
          width: 70%;
          .details2 {
            .item {
              display: flex;
              align-items: center;
              margin: 1em;
              gap: 1em;
            }
            img {
              width: 13%;
            }
          }
          .details3 {
            .item {
              display: flex;
              align-items: center;
              margin: 1em;
              gap: 1em;
            }
            img {
              width: 13%;
            }
          }
        }
      }
      .rent_option {
        padding: 0.8em;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        background-color: #fff;
        // width: 316px;
        border-radius: 5px;
        position: relative;
        width: 35%;
        height: 60%;
        margin-top: 2em;
        position: relative;
        @media (max-width: 750px) {
          width: 95%;
        }
        .pay {
          border: 2px solid rgb(223, 221, 221);
          margin: 0.8em;
          width: 30%;
          padding: 0.15em;
          border-radius: 12px;
          color: rgba(117, 117, 117, 1);
          font-weight: 400;
          font-size: 0.8rem;
        }
        span {
          color: #04c17e;
          font-weight: 600;
          font-size: 0.9rem;
          font-weight: 500;
          margin: 0.8em;
        }
        p {
          color: rgba(117, 117, 117, 1);
          font-weight: 300;
          font-size: 0.7rem;
          margin: 0.8em;
        }
        button {
          width: 90%;
          border: none;
          outline: none;
          color: #fff;
          background-color: $color_primary-100;
          margin: 0.8em;
        }
        .login_create {
          // margin: .8em;
          margin: auto;
          font-size: 0.8rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .lgn_btn {
            border: none;
            outline: none;
            color: #fff;
            background-color: $color_primary-100;
            width: 45%;
          }
          .crt_btn {
            outline: none;
            border: 1px solid $color_primary-100;
            color: $color_primary-100;
            background-color: #fff;
            width: 45%;
            text-align: center;
          }
        }
      }
    }
  }
}

.saved-carousel-image {
  width: 300px;
  height: 360px;
  border-radius: 10px;
  // border: 2px solid red;
  background-position: center;
  @media (max-width: 902px) {
    width: 250px;
    height: 250px;
  }
  @media (max-width: 700px) {
    width: 180px;
    height: 200px;
  }
  @media (max-width: 696px) {
    width: 290px;
    height: 340px;
  }
  @media (max-width: 638px) {
    width: 250px;
  }
  @media (max-width: 600px) {
    width: 420px;
    height: 420px;
  }
  @media (max-width: 480px) {
    width: 380px;
    height: 380px;
  }
  @media (max-width: 400px) {
    width: 320px;
  }
}
.carousel-container {
  width: 100%;
  // padding: 2em;
  // margin: 1em;
  // padding-right: 24px;
  // border: 2px solid red;
  // @media (max-width: 900px) {
  //     width: 90%;
  // }
  @media (max-width: 700px) {
    width: 90%;
  }
  @media (max-width: 696px) {
    width: 100%;
  }
  @media (max-width: 600px) {
    width: 92%;
  }
  @media (max-width: 440px) {
    width: 100%;
    margin: auto;
  }
  @media (max-width: 378px) {
    width: 100%;
  }
}
.caa {
  max-width: 320px;
  min-width: 320px;
  @media (max-width: 900px) {
    max-width: 275px;
    min-width: 275px;
  }
  @media (max-width: 700px) {
    max-width: 190px;
    min-width: 190px;
  }
  @media (max-width: 696px) {
    max-width: 320px;
    min-width: 320px;
  }

  @media (max-width: 600px) {
    max-width: 450px;
    min-width: 450px;
    margin: auto;
  }
  @media (max-width: 440px) {
    max-width: 420px;
    min-width: 420px;
  }
  @media (max-width: 400px) {
    max-width: 350px;
    min-width: 350px;
  }
  @media (max-width: 375px) {
    max-width: 100%;
    min-width: 1px;
  }
}

.detail_icon {
  @media (max-width: 750px) {
    height: 40px;
    width: 36px;
  }
  @media (max-width: 500px) {
    display: none;
  }
}
