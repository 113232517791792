.header_section{
    background-image: url("./assets/background.png");
    /* background-size: cover; */
    background-repeat: repeat-x;
    background-position: center;
    /* background-attachment: fixed; */
    animation: animatedBackground 10s linear infinite alternate;
    width: 100%;
    max-height: 60vh;
    /* margin-top: 2em; */
    border: none;
    margin-bottom: 3em;
    padding-bottom: 2rem;
    @media (max-width: 767px) {
      margin-bottom: 0;
    }
}
@keyframes animatedBackground {
    from {
      background-position: 0 0;
    }
    to {
      background-position: 100% 0;
    }
  }

 @media (max-width: 600px) {
  .header_section{
    height: 50vh;
  }
  @media (max-width: 400px) {
    /* height: 45vh; */
  }
 }