main {
  margin-top: 1.5em;

  // z-index: 1;
  .content {
    margin: auto;

    .slider_div {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      /* 3 columns with equal width */
      gap: 30px;
      width: 95%;
      margin: auto;

      /* Media query for screens smaller than 768px */
      @media (max-width: 900px) {
        grid-template-columns: repeat(3, 1fr);
        width: 90%;
        /* 2 columns per row */
      }

      @media (max-width: 700px) {
        grid-template-columns: repeat(2, 1fr);
        /* 2 columns per row */
      }

      @media (max-width: 600px) {
        grid-template-columns: repeat(1, 1fr);
        gap: 4rem;
      }
      section {
        width: 40%;
        padding: 1rem;
        border-radius: 10px;
        width: 100%;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        .slider {
          height: 100%;
          // width: 27%;
          margin: auto;

          .img {
            height: 320px;
            // height: ;
            width: 100%;
            border-radius: 15px;

            @media (max-width: 900px) {
              max-height: 480px;
            }

            // @media (max-width: 480px) {
            //   // height: 34vh;
            // }
          }

          .info {
            margin-top: 1.5em;

            h2 {
              @include styletext($mds-font-size, $color_primary-300, 600, left);
            }

            p {
              @include styletext($ssm-font-size, $border, 300, left);
              width: 100%;
            }

            .price {
              display: flex;
              align-items: center;
              justify-content: left;
              gap: 0.8em;
              margin-top: 0.5em;

              span {
                @include styletext($ssm-font-size, $color_tertiary-600, 600, left);
              }
            }

            .detailss {
              display: flex;
              align-items: center;
              gap: 0.8em;
              margin-top: 0.5em;
              margin-bottom: 1rem;

              @media (max-width: 900px) {
                margin-bottom: 0;
              }

              ul {
                display: flex;
                align-items: center;
                gap: 0.6em;
                margin: auto;
                img {
                  width: 18px !important;
                  @media (max-width: 900px) {
                    width: 20px !important;
                  }
                }
                @media (max-width: 900px) {
                  justify-content: space-between;
                }

                li {
                  @include styletext($ssm-font-size, $border, 400, left);
                  @media (max-width: 700px) {
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.no_property {
  display: flex;
  justify-content: center;
  margin: auto;
  margin-inline: auto;
  position: relative;
  width: 100%;

  .gif {
    max-width: 400px;
    @media (max-width: 700px) {
      // width: 50%;
      max-width: 300px;
      width: auto;
      height: 400px;
    }

    img {
      cursor: pointer;
      @media (max-width: 700px) {
        width: 100%;
        //  border: 2px solid red;
      }
    }
    .btn {
      width: fit-content;
      margin-inline: auto;
      margin: 1.2rem auto 5rem;
      display: flex;
      justify-content: center;
      button {
        background-color: $color_primary-100 !important;
        color: #fff;
        border: none;
        outline: none;
      }
    }
  }
}
