.slideMain{
    position: relative;
    height: 100%; 
    .heart{
        height: 30px;
        width: 30px;
        border-radius: 50%;
        padding: .1em;
        background: $color_primary-100;
        position: absolute;
        top: 15%;
        transform: translate(0, -50%);
        right: 24px;
        font-size: 45px;
         color: #fff;
        z-index: 1;
        cursor: pointer;
    }
    .arrow_container{
        .leftArrowStyles{
            width: 38px;
            height: 38px;
            background: rgba(40, 123, 220, 1);
            border-radius: 50%;
            opacity: 0.5;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            left: 32px;
            font-size: 45px;
            color: #fff;
            z-index: 1;
            cursor: pointer;
            padding: .2em;
        }
        .rightArrowStyles{
            width: 38px;
            height: 38px;
            background: rgba(40, 123, 220, 1);
            border-radius: 50%;
            opacity: 0.5;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            right: 32px;
            font-size: 45px;
            color: #fff;
            z-index: 1;
            cursor: pointer;
            padding: .2em;
        }
    }
    .dotsContainerStyles{
        display: flex;
        gap: .3rem;
        justify-content: center;
        margin-top: .5rem;
        .dotStyle{
            cursor: pointer;
            font-size: 20px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #c2c1c1;
            transition: all .25s ease-in-out;
        }
        .active_class{
            cursor: pointer;
            font-size: 20px;
            width: 20px;
            height: 10px;
            border-radius: 12px;
            background-color: $color_primary-100;
            transition: all .25s ease-in-out;
        }
    }
  }
