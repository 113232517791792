.zoom {
  background: rgb(92, 92, 92);
  opacity: 0.8;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
  height: 1600px;
  bottom: 0;
  transform: 0.2s linear;
  .x_mark{
    margin-top: 1rem;
    margin-left: 1rem;
  }
  @media (max-width: 900px) {
    height: 2400px;
  }
}

.zoom_content_img {
    border-radius: 8px;
  border: 1px solid rgb(225, 225, 225);
  z-index: 999999999999999999;
  //   width: 0%;
  background: #fff !important;
  //   height: 70%;
  position: absolute;
  top: 38%; /* Move the top edge to the vertical center */
  left: 50%; /* Move the left edge to the horizontal center */
  transform: translate(-50%, -50%);
  @media (max-width: 900px) {
    width: 90%;
    top: 35%;
  }
  @media (max-width: 480px) {
    top: 16%;
  }
  .img_main_zoom {
    border-radius: 8px;
    padding: 4rem;
    background: #fff !important;
    @media (max-width: 900px) {
      padding: 2rem;
    }
    .zoom_ {
      .zoom_image {
        margin: auto;
        max-width: 800px;
        img {
          width: 100%;
          border-radius: 10px;
        }
      }
      .rent_option {
        padding: 0.8em;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        background-color: #fff;
        // width: 316px;
        border-radius: 5px;
        position: relative;
        width: 100%;
        height: 60%;
        margin: 2rem auto auto;
        // margin-top: 2em;
        position: relative;
        @media (max-width: 750px) {
          width: 95%;
        }
        @media (max-width: 480px) {
          width: 100%;
        }
        .pay {
          border: 2px solid rgb(223, 221, 221);
          margin: 0.8em;
          width: 30%;
          padding: 0.15em;
          border-radius: 12px;
          color: rgba(117, 117, 117, 1);
          font-weight: 400;
          font-size: 0.8rem;
        }
        span {
          color: #04c17e;
          font-weight: 600;
          font-size: 0.9rem;
          font-weight: 500;
          margin: 0.8em;
        }
        p {
          color: rgba(117, 117, 117, 1);
          font-weight: 300;
          font-size: 0.7rem;
          margin: 0.8em;
        }
        button {
          width: 90%;
          border: none;
          outline: none;
          color: #fff;
          background-color: $color_primary-100;
          margin: 0.8em;
        }
        .login_create {
          // margin: .8em;
          margin: auto;
          font-size: 0.8rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .lgn_btn {
            border: none;
            outline: none;
            color: #fff;
            background-color: $color_primary-100;
            width: 45%;
          }
          .crt_btn {
            outline: none;
            border: 1px solid $color_primary-100;
            color: $color_primary-100;
            background-color: #fff;
            width: 45%;
            text-align: center;
          }
        }
      }
    }
  }
}
