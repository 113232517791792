*,
*::before,
*::after {
  box-sizing: border-box;
}
// ::-webkit-scrollbar {
//   display: none;
// }

* {
  margin: 0;
  padding: 0;
  // transition: all .2s ease-in;
}

html,
body {
  scroll-behavior: smooth; 
  overflow-x: hidden ;
  transition: all .2s ease-in;
}

body {
  line-height: 1.5;
  font-size: 16px !important;
  -webkit-font-smoothing: antialiased;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
  font-size: 100% !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

ol,
ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button:hover {
  cursor: pointer !important;
}

// input {
//   font-size: 16px !important;
// }

// .back-button {
//   display: none;

//   @include from-tablet-down {
//     display: block;
//   }
// }

.back-button:hover {
  cursor: pointer;
}
svg:hover {
  cursor: pointer;
}
.wrapper {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
}



// .active{
//   color:$color_primary-500;
// }
// #root{
//   isolation: isolate;
// }
