.header_section{
   
    .btn_div{
        width: 15%;
        margin: auto auto 2em;
        padding-top: 1.5em;
        
        @media (max-width: 400px) {
            padding-top: 1.8em;
            width: 27%;
        }
       button{
        background-color: $color_primary-100;
        outline: none;
        border: none;
        color: #fff;
        width: 100%;
        cursor: pointer;
       }
    }
    .search_parameters{
        padding-top: 10em;
        @media (max-width: 440px) {
            padding-top: 4.8em;
        }
        .content{
            padding: .8em;
            .input_type{
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 1.5em;
                // width: 90%;
                // border: 2px solid red;
                @media (max-width: 400px) {
                    gap: .6em;
                }
                input{
                    padding: .8em;
                    border: none;
                    outline: none;
                    width: 35.5%;
                    border-radius: 5px;
                    &::placeholder{
                        @media (max-width: 400px) {
                            font-size: .8rem;
                        }
                    }
                    @media (max-width: 600px) {
                        width: 42%;
                    }
                    @media (max-width: 400px) {
                        width: 60%;
                    }
                }
                .select{
                    // border: 2px solid red;
                   
                    width: 17%;
                    background-color: #fff;
                    height: 3em;
                    border-radius: 5px;
                    padding-left: .8em;
                    outline: none;
                    border: none;
                    @media (max-width: 600px) {
                        width: 25%;
                    }
                    @media (max-width: 400px) {
                        width: 35%;
                    }
                }
            }
            .details_filter{
                // border: 2px solid red;
                margin:2em auto auto;
                gap: 1.5em;
                display: flex;
                justify-content: center;
                align-items: center;
                // margin-left: 1.5em;
                @media (max-width: 400px) {
                    gap: .6em;
                    width: 100%;
                }
                .select{
                    // border: 2px solid red;
                    background-color: #fff;
                    width: 17%;
                    background-color: #fff;
                    height: 3em;
                    border-radius: 5px;
                    padding-left: .8em;
                    outline: none;
                    border: none;
                    @media (max-width: 600px) {
                        width: 25%;
                    }
                        @media (max-width: 400px) {
                        width: 38%;
                        font-size: .8rem;
                    }
                }
            }
        }
    }
}

select{
  @media (max-width: 480px) {
    -webkit-appearance: none;
  }
}

.location_search_div{
    position: relative;
    width: 60%;
   @media (min-width: 900px) {
    width: 35%;
   }
    .location{
        width: 100% !important;
    }
    .search_values{
        // position: absolute;
        background: #fff;
        width: 100%;
        padding: .8em;
        border-radius: 3px;
        margin-top: 0em;
        z-index: 1;
        overflow: hidden;
        overflow-y: auto;
        min-height: fit-content;
        max-height: 300px;
        opacity: .95;
        p{
            margin: .5em auto .5em;
            cursor: pointer;
            padding: .5em;
            &:hover{
                background: rgb(163, 162, 162);
            }
        }
    }
    .spinner{
        position: absolute;
        z-index: 2;
        display: flex;
        align-items: center;  
        justify-content: center;
        width: 100%;
    }
}