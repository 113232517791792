@mixin layout {
    display: grid;
    grid-template-columns: 30% 36% 32%;
    align-content: center;
    // grid-gap: 40px;
}

@mixin layout-flex($gap: 8em, $align-items: center) {
    display: flex;
    align-items: $align-items;
    height: 100vh;
    gap: $gap;
    overflow: hidden;
}
@mixin layout-flex-w($width: 100%, $justify-content: space-between){
    display: flex;
    align-items: center;
    justify-content: $justify-content;
    width: $width;
}
@mixin input-form{
    display: flex;
    flex-direction: column;

}

@mixin styletext($fontsize, $color, $fontweight:400, $textalign: center) {
    color: $color;
    font-size: $fontsize;
    font-weight: $fontweight;
    text-align: $textalign;
}

@mixin imagetext($fontsize, $bottom, $font-weight ){
    position: absolute;
    bottom: $bottom;
    color: #fff;
    font-size: $fontsize;
    font-weight: $font-weight;
}

// responsiveness 
@mixin for-mobile-only {
    @media (max-width: 400px) {
        @content;
    }
}



@mixin from-tablet-down {
    @media (max-width: 900px) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: 600px) {
        @content;
    }
}

@mixin larget_mobile{
    @media (max-width: 600px){
        @content
    }
}