header {
    width: 100%;
    max-width: 1800px;
    transition: all .45s ease-in;
     position: fixed;
     top: 0;
     background: #fff;
     z-index: 9999;
    .top_content {
        display: flex;
        justify-content: space-between;
        // margin: auto;
        align-items: center;
        padding: 1.3em 1.3em 1.3em 3em;
        background: #fff;
       width: 100%;
        z-index: 9999;

        @media (max-width: 600px) {
            // width: 100%;
            // margin-left: 5em;
            // justify-content: space-around;
            // align-items: center;
            // border: 2px solid red;
            // margin: 0;
            // padding: 1em;
        }

        @media (max-width: 400px) {
            width: 100%;
            padding: .8rem .4rem .8rem 1.1rem;
        }

        ul {
            .horizontal_line {
                width: 100%;
                height: 1.7px;
                background-color: rgb(164, 164, 164);
            }

            .line {
                margin-bottom: 1em;
            }

            .l {
                font-size: 1.2rem;
                color: $border;
                font-weight: 500;
            }
        }

        .img_logo {
            width: 10rem;

            @media (max-width: 600px) {
                width: 30%;
                // margin-top: 1em;
            }

            @media (max-width: 450px) {
                // margin: 0 auto;
            }

        }

        .profile {
            display: flex;
            align-items: center;
            gap: 1em;
            position: relative;

            @media (max-width: 600px) {
                font-size: .9rem;
            }

            h4 {
                @media (max-width: 600px) {
                    display: none;
                }

                @media (max-width: 400px) {}
            }

        }

        .btn_b {
            margin-right: 2em;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1em;

            @media (max-width: 500px) {
                margin-right: .5em;
            }

            @media (max-width: 400px) {
                gap: .5px;
            }

            @media (max-width: 826px) {
                display: none;
            }

            .signin_btn {
                color: $color_primary-100;
                border: none;
                outline: none;
                border: 1px solid $color_primary-100;

                @media (max-width: 500px) {
                    padding: .1em !important;
                    font-size: .7rem !important;
                }
            }

            .login_btn {
                background-color: $color_primary-100;
                color: #fff;
                outline: none;
                border: none;

                @media (max-width: 500px) {
                    font-size: .8rem;
                }
            }
        }

    }
}

// .pop{
//     h4{
//         @media (max-width: 600px) {
//             font-size: .9rem;
//             border: 2px solid red;
//         }
//     }
// }
.d {
    margin: auto;

    @media (max-width: 750px) {
        margin-right: 1em;
    }
}

// ADDED NEW FEATURE AT THE TOP NAV LIST STYLES 
.explorer_navigation_header_list {
    display: flex;
    transition: all .3s ease-in;
    gap: 6.5em;
    align-items: center;
    @media (max-width: 900px) {
        gap: 3em;
    }

    @media (max-width: 900px) {
        display: none;
    }

    .link {
        color: #000;
        padding: .3em;
        transition: all .35s ease-in;
        &:hover {
            border-bottom: 3px solid $color_primary-100;
            transition: all .3s ease-in;
        }

        li {
            font-size: 1.2rem;
            font-weight: 500;

            @media (max-width: 1166px) {
                font-size: 1rem;
            }
        }
    }
}

.active {
    border-bottom: 3px solid $color_primary-100;
}
.mobile_main{
    width: 100%;
    height: 100vh;
    position: fixed;
    // right: ${(props) => (props.togglehamburger ? "30%" : "100%")};
    right: 100%;
    display: block;
    z-index: 98;
    transition: 0.4s;
    background-color: #fff;
    // color: #fff;
    opacity: .9;
    z-index: 2;
    @media (min-width: 900px) {
      display: none;
    }
  
}
.mobile_main_click{
      width: 100%;
      min-height: 100vh;
      position: fixed;
      right: 0;
      // right: ${(props) => (props.togglehamburger ? "30%" : "100%")};
      display: block;
      z-index: 98;
      transition: 0.4s;
      background-color: #fff;
      // color: #fff;
      opacity: .9;
      z-index: 2;
    //   border: 2px solid red;
      @media (min-width: 900px) {
        display: none;
      }
}

.mobile {
    // height: 100vh;
    // opacity: .9;
    // z-index: 2;
    // position: absolute;
    // transition: all .4s ease-in;
    // background-color: #fff;
    

    .lin {
        transition: all .5s ease;

        .mobile_nav_line {
            border: 1px solid rgb(201, 199, 199);
        }
    }

    .list {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: fit-content;
        margin: 2em auto auto;

        .link {
            color: #000;
            transition: all .35s ease-in;
            margin-bottom: 2em;

            &:hover {
                border-bottom: 3px solid $color_primary-100;
                transition: all .3s ease-in;
            }

            li {
                font-size: 1.5rem;
                font-weight: 500;

                @media (max-width: 420px) {
                    font-size: 1rem;
                }
            }
        }

        .active {
            border-bottom: 3px solid $color_primary-100;
            width: fit-content;
        }
    }

}

.bars {
    display: none;

    @media (max-width: 900px) {
        display: block;
    }

    @media (max-width: 400px) {
        width: 20px !important;
        height: 20px !important;
        margin-right: 1em;
    }
}

// puuting the login and create account btn in the mobile nav dropdown as well as the email and name for loggedin users
.popup_mobile {
    margin: auto;
    display: flex;
    justify-content: center;

}

.btn_b_mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5em;

    .signin_btn {
        border: 1.5px solid $color_primary-100;
        color: $color_primary-100;
        // border: none;
        outline: none;

        @media (min-width: 400px) {
            font-size: .8rem;
        }
    }

    .login_btn {
        width: 9rem;
        background-color: $color_primary-100;
        color: #fff;
        outline: none;
        border: none;

        @media (min-width: 400px) {
            font-size: .8rem;
        }
    }
}

.avat {
    @media(max-width: 900px) {
        display: none;
    }
}