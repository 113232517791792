.pagination-container{
        .pagination {
            display: flex;
            justify-content: center;
            margin-top: 2.5rem;
            align-items: center;
            gap: 2rem;
            .active {
                color: #fff;
                background: #00a8ff;
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                cursor: pointer;
            }
            .page_main{
                color: #00a8ff;
                background: #f5f0f0;
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                cursor: pointer;
            }
            .page-number{
                cursor: pointer;
            }
        }
    }